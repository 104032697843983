function App () {
  return (
    <div className="bg-blue-500 text-white p-4">
      <h1 className="text-2xl">Hello, Tailwind CSS!</h1>
      <p className="mt-2">Tailwind CSS is working in your React project!</p>
    </div>
  )
}

export default App
